import { DatePicker as AntDatePicker, ConfigProvider } from 'antd/es'
import type { Dayjs } from 'dayjs'
import type { FC, SetStateAction } from 'react'
import { useState } from 'react'
import { createGlobalStyle, useTheme } from 'styled-components'

import { StyledDatePicker, datePickerStyles, rangePickerTightStyles } from '@styles/datepicker/datepicker'

import { datePickerButtons } from './components/DatePickerButtons'

type Props = {
  isSingleDate?: boolean
}

const { RangePicker } = AntDatePicker

const DatePicker: FC<Props> = ({ isSingleDate = false }) => {
  const theme = useTheme()
  const [singleDate, setSingleDate] = useState<Dayjs | null>(null)

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            colorText: theme?.colors.fontMain,
          },
        },
        token: {},
      }}
    >
      <StyledDatePicker>
        {isSingleDate ? (
          <AntDatePicker
            onChange={value => setSingleDate(value as SetStateAction<Dayjs | null>)}
            renderExtraFooter={() => null}
            value={singleDate}
            {...datePickerButtons}
          />
        ) : (
          <RangePicker renderExtraFooter={() => null} {...datePickerButtons} />
        )}
      </StyledDatePicker>
      <GlobalStyles />
    </ConfigProvider>
  )
}

const GlobalStyles = createGlobalStyle`
  .ant-picker-dropdown {
    max-width: 398px;
    ${datePickerStyles};
    ${rangePickerTightStyles};
  }
`

export default DatePicker

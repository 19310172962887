import type { ButtonProps as AntButtonProps } from 'antd/es'
import { ConfigProvider } from 'antd/es'
import type { FC, ReactNode } from 'react'
import { useTheme } from 'styled-components'

import type { ThemeColorsType } from '@themes/themes'

import { StyledButton } from './styles'

export type ButtonProps = {
  children: ReactNode | string
  iconColor?: ThemeColorsType
  iconColorHover?: ThemeColorsType
} & AntButtonProps

export const Button: FC<ButtonProps> = ({ children, iconColor, iconColorHover, type = 'primary', ...props }) => {
  const theme = useTheme()

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            borderRadius: 16,
            colorBgContainer: theme?.colors.primary,
            colorBorder: 'none',
            colorLink: theme?.colors.primary,
            colorLinkActive: theme?.colors.primary,
            colorLinkHover: theme?.colors.primary,
            colorPrimary: theme?.colors.primary,
            colorPrimaryActive: theme?.colors.primary,
            colorPrimaryBorder: '',
            colorPrimaryHover: theme?.colors.primary,
            colorText: theme?.colors.white,
            colorTextDisabled: theme?.colors.primary,
            fontSize: 14,
            paddingXS: 22,
          },
        },
        token: {},
      }}
    >
      <StyledButton $iconColor={iconColor} $iconColorHover={iconColorHover} {...props} type={type}>
        {children}
      </StyledButton>
    </ConfigProvider>
  )
}

export default Button

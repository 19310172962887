import type { MouseEvent } from 'react'
import { flushSync } from 'react-dom'

export default (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
  flushSync(() => {
    ;(
      e.currentTarget.closest('.ant-picker-panel-layout')?.querySelector('.ant-picker-ok button') as HTMLButtonElement
    )?.click()
  })
}

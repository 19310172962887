import { css } from 'styled-components'

import { appearFromLeft, appearFromTop, zoomAppear } from '@styles/animations/popups/appear'

export const disappearToLeft = css`
  ${appearFromLeft};
  animation-direction: reverse;
`

export const disappearToTop = css`
  ${appearFromTop};
  animation-direction: reverse;
`

export const zoomDisappear = css`
  ${zoomAppear};
  animation-direction: reverse;
`

export const searchFormSizes = {
  buttonHeightDesktop: 64,
  buttonHeightMobile: 64,
  fieldHeightDesktop: 88,
  fieldHeightMobile: 84,
  formWidthDesktop: 392,
  formWidthTablet: 372,
  layoversHeightDesktop: 64,
  layoversHeightMobile: 48,
  marginBlockDesktop: 54,
  paddingBottomDesktop: 24,
  paddingBottomMobile: 32,
  popupWidthDesktop: 398,
  popupWidthTablet: 362,
  switcherHeightDesktop: 64,
  switcherHeightMobile: 48,
}

export const topPartHeightDesktop = searchFormSizes.fieldHeightDesktop * 3
export const topPartHeightMobile = searchFormSizes.fieldHeightMobile * 3

export const formHeightDesktop =
  topPartHeightDesktop +
  searchFormSizes.fieldHeightDesktop +
  searchFormSizes.buttonHeightDesktop +
  searchFormSizes.paddingBottomDesktop

export const formHeightMobile =
  topPartHeightMobile +
  searchFormSizes.fieldHeightMobile +
  searchFormSizes.buttonHeightMobile +
  searchFormSizes.paddingBottomMobile

import { css } from 'styled-components'

const basicLargeStyles = css`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.16px;
`

// Txt/Large 1
export const textLargeRegularStyles = css`
  ${basicLargeStyles};
`

// Txt/Large 2
export const textLargeItalicStyles = css`
  ${basicLargeStyles};
  font-style: italic;
`

// Txt/Large 3
export const textLargeMediumStyles = css`
  ${basicLargeStyles};
  line-height: 24px;
  font-weight: 500;
`

const basicMediumStyles = css`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.14px;
`

// Txt/Medium 2
export const textMediumRegularStyles = css`
  ${basicMediumStyles};
`
// Txt/Medium 1
export const textMediumItalicStyles = css`
  ${basicMediumStyles};
  font-style: italic;
`

// Txt/Medium 3
export const textMediumMediumStyles = css`
  ${basicMediumStyles};
  font-weight: 500;
  line-height: 22px;
`

const basicSmallStyles = css`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
`

// Txt/Small 1
export const textSmallRegularStyles = css`
  ${basicSmallStyles};
`

export const textSmallItalicStyles = css`
  ${basicSmallStyles};
  font-style: italic;
`

// Txt/Small 2
export const textXSmallRegularStyles = css`
  font-size: 10px;
  line-height: 18px;
`

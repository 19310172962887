import { css, keyframes } from 'styled-components'

import {
  scaleXOpacityHided,
  scaleXOpacityShowed,
  scaleYOpacityHided,
  scaleYOpacityShowed,
  zoomHided,
  zoomShowed,
} from '@styles/animations/popups/animationStyleStates'

const scaleXOpacityAppear = keyframes`
  0% {
    ${scaleXOpacityHided};
  }
  100% {
    ${scaleXOpacityShowed};
  }
`
export const appearFromLeft = css`
  transform-origin: left;
  animation-name: ${scaleXOpacityAppear};
`

const scaleYOpacityAppear = keyframes`
  0% {
    ${scaleYOpacityHided};
  }
  100% {
    ${scaleYOpacityShowed};
  }
`

export const appearFromTop = css`
  transform-origin: top;
  animation-name: ${scaleYOpacityAppear};
`

const zoomAppearKeyframes = keyframes`
  0% {
    ${zoomHided};
  }
  100% {
    ${zoomShowed};
  }
`

export const zoomAppear = css`
  transform-origin: center;
  animation-name: ${zoomAppearKeyframes};
`

import styled from 'styled-components'

import ArrowLeftIcon from '@iconscout/unicons/svg/line/arrow-left.svg'
import ArrowRightIcon from '@iconscout/unicons/svg/line/arrow-right.svg'

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 8px;
  background: ${p => p.theme.colors.backgroundAdditional};

  &:hover {
    background: ${p => p.theme.colors.backgroundBlueContainer};
    transition: 0.3s;
  }

  svg {
    fill: ${p => p.theme.colors.icon};
  }
`

export const datePickerButtons = {
  nextIcon: (
    <IconWrapper>
      <ArrowRightIcon />
    </IconWrapper>
  ),
  prevIcon: (
    <IconWrapper>
      <ArrowLeftIcon />
    </IconWrapper>
  ),
  superNextIcon: null,
  superPrevIcon: null,
}

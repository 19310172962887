import { css } from 'styled-components'

export const scaleXOpacityShowed = css`
  transform: scaleX(1);
  opacity: 1;
`
export const scaleXOpacityHided = css`
  transform: scaleX(0.8);
  opacity: 0;
`

export const scaleYOpacityShowed = css`
  ${scaleXOpacityShowed};
  transform: scaleY(1);
`
export const scaleYOpacityHided = css`
  ${scaleXOpacityHided};
  transform: scaleY(0.8);
`

export const zoomShowed = css`
  ${scaleXOpacityShowed};
  transform: scaleY(1);
`
export const zoomHided = css`
  transform: scale(0.2);
  opacity: 0;
`

import { css } from 'styled-components'

const basicTitleStyles = css`
  font-weight: 700;
  line-height: 150%;
  display: block;
  //font-family: 'DM Sans';
`

export const title1Styles = css`
  ${basicTitleStyles};
  font-size: 38px;
  letter-spacing: -0.076px;
`

export const title2Styles = css`
  ${basicTitleStyles};
  font-size: 32px;
  letter-spacing: -0.064px;
`

export const title3Styles = css`
  ${basicTitleStyles};
  font-size: 28px;
  letter-spacing: -0.064px;
`

export const title4Styles = css`
  ${basicTitleStyles};
  font-size: 24px;
  letter-spacing: -0.048px;
`

export const title5Styles = css`
  ${basicTitleStyles};
  font-size: 18px;
  letter-spacing: -0.036px;
`

export const title6Styles = css`
  ${basicTitleStyles};
  font-size: 16px;
  letter-spacing: -0.16px;
`

export const title7Styles = css`
  ${basicTitleStyles};
  font-size: 14px;
  letter-spacing: -0.14px;
`

export const title8Styles = css`
  ${basicTitleStyles};
  font-size: 12px;
  letter-spacing: -0.12px;
`

export const title9Styles = css`
  ${basicTitleStyles};
  font-size: 10px;
  letter-spacing: -0.1px;
`

import AntButton from 'antd/es/button'
import type { ButtonType } from 'antd/es/button/buttonHelpers'
import styled from 'styled-components'

import { captionMediumStyles, captionSmallStyles } from '@styles/typography/caption'
import type { ThemeColorsType } from '@themes/themes'

const defaultColors: Record<'iconColor' | 'iconColorHover', Partial<Record<ButtonType, ThemeColorsType>>> = {
  iconColor: {
    link: 'primary',
    text: 'primary',
  },
  iconColorHover: {
    link: 'primary',
    text: 'white',
  },
}

export const StyledButton = styled(AntButton)<{
  $iconColor?: ThemeColorsType
  $iconColorHover?: ThemeColorsType
  type?: ButtonType
}>`
  &.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    padding: 20px 50px;

    & > svg:first-child:not(& > svg:last-child),
    & > div + svg {
      margin-inline-end: 16px;
    }

    & > svg {
      fill: ${p => p.theme.colors[p.$iconColor || (p.type && defaultColors.iconColor[p.type]) || 'white']};
      transition: ${p => p.theme.transitions.default};

      &:last-child:not(&:first-child) {
        margin-inline-start: 16px;
      }
    }

    &:disabled {
      background: ${p => p.theme.colors.primary};
      color: ${p => p.theme.colors.white};
      opacity: 30%;
    }

    &:hover {
      box-shadow: ${p => p.theme.shadows.button};

      svg {
        fill: ${p => p.theme.colors[p.$iconColorHover || (p.type && defaultColors.iconColorHover[p.type]) || 'white']};
      }
    }

    &:focus {
      background: ${p => p.theme.colors.primary75};
      color: ${p => p.theme.colors.white};
    }

    &.ant-btn-primary {
      .ant-spin .ant-spin-dot-item {
        background: ${p => p.theme.colors.white};
      }

      *:not(svg) {
        color: ${p => p.theme.colors.white};
      }
    }

    &.ant-btn-default {
      &:hover,
      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: ${p => p.theme.colors.primary};
        color: ${p => p.theme.colors.white};
        box-shadow: ${p => p.theme.shadows.button};
      }

      span {
        color: inherit;
      }
    }

    &.ant-btn-text {
      ${captionMediumStyles};
      padding: 14px 25px;
      background: ${p => p.theme.colors.backgroundButtonContainer};

      span,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${p => p.theme.colors.buttonText};
      }

      svg {
        fill: ${p => p.theme.colors.buttonText};
      }

      &:disabled {
        opacity: 0.5;
        box-shadow: none;
      }

      &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: ${p => p.theme.colors.primary};
        box-shadow: none;

        span {
          color: ${p => p.theme.colors.white};
        }

        svg,
        svg path {
          fill: ${p => p.theme.colors.white};
        }
      }

      &:focus,
      &:active {
        opacity: 0.75;
        box-shadow: none;
      }
    }

    &.ant-btn-link {
      ${captionSmallStyles};
      padding: 0;

      &[disabled],
      &:hover,
      &:focus,
      &:active {
        background: none;
        box-shadow: none;
        color: ${p => p.theme.colors.primary};
      }

      svg,
      svg path {
        fill: ${p => p.$iconColor || p.theme.colors.fontSecondary};
      }

      &:hover {
        svg,
        svg path {
          fill: ${p => p.theme.colors.primary};
        }
      }

      svg {
        &:first-child:not(&:last-child) {
          margin-inline-end: 12px;
        }

        &:last-child:not(&:first-child) {
          margin-inline-start: 12px;
        }
      }
    }
  }
`

import { css } from 'styled-components'

export const captionLargeStyles = css`
  font-size: 48px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.096px;
`

export const captionMediumStyles = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
`

export const captionSmallStyles = css`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
`

export const captionUltraSmallStyles = css`
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`

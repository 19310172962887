import type { FC } from 'react'

import CloseIcon from '@iconscout/unicons/svg/line/times.svg'

import { WrapperClearReturnDateButton } from './styles'

type Props = {
  onClear: () => void
}
export const ClearReturnDateButton: FC<Props> = ({ onClear }) => {
  return (
    <WrapperClearReturnDateButton onClick={onClear}>
      <CloseIcon />
    </WrapperClearReturnDateButton>
  )
}
